.certificatesContainer {
  display: flex;
}

.certificatesList {
  padding: 0 !important;
  margin-left: 380px !important;
}

.certificateContent {
  padding: 0 !important;
  background: none !important;
  position: relative;
}

.certificateTitle span {
  font-size: 40px !important;
  text-transform: uppercase;
  color: #fefaed;
  display: flex;
  align-items: center;
}

.certificateImg {
  max-width: 348px;
  position: absolute;
  z-index: 1;
  filter: sepia(100%) hue-rotate(150deg) saturate(100%);
  max-height: 300px;
  left: 300px;
}

.activeCertificate span {
  color: #62def0 !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .certificatesContainer {
    flex-direction: column;
  }

  .certificatesList {
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .certificateTitle span {
    font-size: 20px !important;
  }

  .certificateImg {
    max-width: 220px;
    position: absolute;
    z-index: 1;
    left: 120px;
    filter: sepia(100%) hue-rotate(150deg) saturate(100%);
    max-height: 200px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .certificatesList {
    margin-left: 126px !important;
  }

  .certificateTitle span {
    font-size: 32px !important;
  }

  .certificateImg {
    max-width: 300px;
  }
}

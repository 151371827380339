.practicesBox {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.practiceCard {
  max-width: 348px;
  height: 600px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-size: cover;
  background-position: 20% 60%;
  box-shadow: inset 0px 0px 70px 150px rgba(0, 0, 0, 0.5) !important;
}

.practiceCardTitle {
  color: #fefaed;
  font-size: 32px !important;
}

.css-46bh2p-MuiCardContent-root {
  padding: 20px !important;
}

.practiceCardDescription {
  color: #fefaed;
  margin-bottom: 20px !important;
  font-size: 18px !important;
}

.practiceCardFooter {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px !important;
}

.buttonInPracticeCard {
  width: 308px;
  height: 40px;
  background: #62def0 !important;
  border-radius: 30px !important;
  color: #1d1d1d !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
}

.buttonInPracticeCard a {
  text-decoration: none !important;
  color: #1d1d1d !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .practicesBox {
    margin-top: 20px;
  }

  .practiceCardCarousel {
    width: 260px !important;
    height: 437px;
    margin-left: 10px;
  }

  .practiceCardCarousel:first-child {
    margin-left: 0px;
  }

  .practiceCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .practiceCardTitle {
    font-size: 20px !important;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 10px !important;
  }

  .practiceCardDescription {
    margin-bottom: 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    min-height: 45px;
  }

  .practiceCardFooter {
    padding: 10px !important;
  }

  .buttonInPracticeCard {
    width: 235px;
    font-size: 14px !important;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .practiceCard {
    max-width: 230px;
    height: 500px;
    background-position: 25% 60%;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 10px !important;
  }

  .practiceCardTitle {
    font-size: 24px !important;
  }

  .practiceCardDescription {
    font-size: 16px !important;
  }

  .buttonInPracticeCard {
    width: 220px;
    font-size: 16px !important;
  }

  .practiceCardFooter {
    padding: 10px !important;
    min-height: 155px;
  }
}

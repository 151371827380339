.advantagesBox {
  margin-top: 40px;
}

.css-h4y409-MuiList-root {
  padding: 0 !important;
}

.listItem {
  border-top: #62def0 1px solid !important;
  background: none !important;
  padding: 30px 0px !important;
}

.listItem:last-child {
  border-bottom: #62def0 1px solid !important;
}

.listItemNum {
  max-width: 21px;
}

.listItemNum span {
  color: #fefaed;
  font-size: 18px;
}

.advantageImg {
  width: 243px;
  height: 314px;
  position: absolute;
  margin-left: 161px;
  z-index: 1;
  top: -50%;
  object-fit: cover;
  object-position: 60% 40%;
}

.listItemText {
  text-align: start;
  padding-left: 525px;
}

.listItemText span {
  color: #fefaed;
  font-size: 32px;
}

.listItemPoint {
  display: flex;
  justify-content: flex-end;
  min-width: 10px;
}

.activeListItem {
  background: #62def0 !important;
}

.activeListItem .listItemNum span {
  color: #1d1d1d !important;
}

.activeListItem .listItemText span {
  color: #1d1d1d !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .advantagesBox {
    margin-top: 20px;
  }

  .listItem {
    padding: 0 !important;
    padding: 10px 0px !important;
  }

  .listItemNum {
    max-width: 16px;
  }

  .listItemNum span {
    font-size: 14px;
  }

  .listItemText {
    padding-left: 10px;
  }

  .listItemText span {
    font-size: 14px;
  }
}

@media (min-width: 640px) and (max-width: 900px) and (orientation: portrait) {
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .listItemText {
    padding-left: 276px;
  }

  .advantageImg {
    margin-left: 60px;
    width: 183px;
    height: 237px;
  }

  .listItemText span {
    font-size: 24px;
  }

  .listItemNum span {
    font-size: 16px;
  }
}

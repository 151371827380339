.videoMainContainer {
  width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoCourseContent {
  width: 1440px;
  margin-left: 80px;
}

.titleVideoCourse {
  margin: 0 0 0 546px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
  white-space: pre-line;
  max-width: 800px;
  margin-top: 60px;
}

.titleVideoCourseColor {
  color: #62def0;
}

.introductionVideo {
  margin: 40px 0 0 546px;
}

.addVideoContainer,
.mainVideoContainer,
.breathVideoContainer,
.workshopVideoContainer {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}

.addVideoTitleBox,
.mainVideoTitleBox,
.breathVideoTitleBox,
.workshopVideoTitleBox {
  display: flex;
  width: 100%;
}

.titleAddVideo,
.titleMainVideo,
.titleBreathVideo,
.titleWorkshopVideo {
  margin: 0 0 0 380px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
  white-space: pre-line;
}

.addVideoBox {
  display: flex;
  justify-content: space-between;
  width: 840px;
  margin: 40px 0 0 546px;
}

.addVideoBtn,
.breathVideoBtn,
.workshopVideoBtn {
  background: #62def0;
  border-radius: 30px;
  width: 170px;
  height: 60px;
  font-size: 18px;
  padding: 0px;
  border: none;
  cursor: pointer;
}

.breathVideoBtn {
  width: 200px;
  height: 60px;
  margin-bottom: 10px;
}

.workshopVideoBtn {
  width: 200px;
  height: 60px;
  margin-bottom: 10px;
}

.addVideoBtn a,
.breathVideoBtn a,
.workshopVideoBtn a {
  text-decoration: none;
  color: #1d1d1d;
}

.mainVideoText {
  display: flex;
  color: #fefaed;
  font-size: 18px;
  margin: 20px 0px 0px 546px;
  width: 500px;
}

.videoFirstGroup,
.videoSecondGroup,
.videoThirdGroup {
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.videoFirstGroup div,
.videoSecondGroup div,
.videoThirdGroup div {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.videoFirstGroup a,
.videoSecondGroup a,
.videoThirdGroup a {
  text-decoration: none !important;
}

.videoCourseCardTitle {
  font-size: 32px;
}

.oilsAndBreathDescription {
  font-size: 18px;
}

.videoCourseCard {
  background-size: cover;
  background-position: 20% 60%;
  box-shadow: inset 0px 0px 70px 150px rgba(0, 0, 0, 0.5) !important;
  color: #fefaed;
}

.videoCourseCard .startVideoBtn {
  background: #62def0;
  color: #1d1d1d;
}

.videoFirstGroup div:nth-child(1) {
  display: flex;
  flex-direction: column;
  width: 428px;
  height: 580px;
  margin-right: 16px;
}

.videoFirstGroup div:nth-child(2),
.videoFirstGroup div:nth-child(4) {
  display: flex;
  flex-direction: column;
  width: 268px;
  height: 360px;
}

.videoFirstGroup div:nth-child(2) {
  margin-right: 16px;
}

.videoFirstGroup div:nth-child(2) .startVideoBtn,
.videoFirstGroup div:nth-child(4) .startVideoBtn {
  width: 228px;
}

.videoFirstGroup div:nth-child(2) a button,
.videoFirstGroup div:nth-child(4) a button {
  text-decoration: none !important;
}

.videoFirstGroup div:nth-child(3) {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 360px;
  margin: 255px 124px 0px 0px;
}

.videoSecondGroup div:nth-child(1),
.videoSecondGroup div:nth-child(4) {
  display: flex;
  flex-direction: column;
  width: 268px;
  height: 360px;
}

.videoSecondGroup div:nth-child(1) {
  margin: 255px 124px 0px 0px;
}

.videoSecondGroup div:nth-child(2) {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 360px;
  margin-right: 16px;
}

.videoSecondGroup div:nth-child(3) {
  display: flex;
  flex-direction: column;
  width: 428px;
  height: 580px;
  margin-right: 16px;
}

.videoSecondGroup div:nth-child(3) a button {
  text-decoration: none !important;
}

.videoThirdGroup div:nth-child(1) {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 360px;
  margin-right: 16px;
}

.videoThirdGroup div:nth-child(2) {
  display: flex;
  flex-direction: column;
  width: 268px;
  height: 360px;
}

.videoThirdGroup div:nth-child(1) a button {
  text-decoration: none !important;
}

.startVideoBtn {
  width: 100%;
  height: 40px;
  border: none;
  background: #62def0;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startVideoBtn a {
  color: #333333 !important;
  font-size: 18px !important;
  text-decoration: none !important;
}

.breathVideoBox {
  display: flex;
  justify-content: space-between;
  width: 840px;
  margin: 40px 0 40px 546px;
}

.workshopVideoBox {
  display: flex;
  justify-content: space-between;
  width: 450px;
  margin: 40px 0 40px 546px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .videoMainContainer {
    width: 360px;
  }

  .videoCourseContent {
    width: 340px;
    margin-left: 0px;
  }

  .titleVideoCourse {
    margin: 0px;
    font-size: 20px;
    line-height: 20px;
    max-width: 100%;
    margin-top: 40px;
  }

  .introductionVideo {
    margin: 20px 0 0 0;
  }

  .introductionVideo div {
    width: 100% !important;
  }

  .addVideoContainer,
  .mainVideoContainer,
  .breathVideoContainer,
  .workshopVideoContainer {
    margin-top: 60px;
  }

  .addVideoTitleBox,
  .mainVideoTitleBox,
  .breathVideoTitleBox,
  .workshopVideoTitleBox {
    flex-direction: column;
  }

  .titleAddVideo,
  .titleMainVideo,
  .titleBreathVideo,
  .titleWorkshopVideo {
    margin: 20px 0 0 0;
    font-size: 20px;
    line-height: 20px;
  }

  .addVideoBox {
    width: 100%;
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .addVideoBtn,
  .breathVideoBtn,
  .workshopVideoBtn {
    font-size: 14px;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }

  .mainVideoText {
    font-size: 14px;
    margin: 20px 0px 0px 0px;
    width: 100%;
  }

  .videoCourseCarousel {
    width: 260px !important;
    height: 437px;
    margin: 40px 0 0 10px;
  }

  .videoCourseCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .videoCourseCardMobile {
    background-size: cover;
    background-position: 20% 60%;
    box-shadow: inset 0px 0px 70px 150px rgba(0, 0, 0, 0.5) !important;
    color: #fefaed;
    max-width: 348px;
    height: 400px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .videoCourseTitle {
    color: #fefaed;
    font-size: 20px !important;
    margin-bottom: 40px !important;
  }

  .videoCourseCardFooter {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px !important;
  }

  .videoCourseCardDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fefaed;
    margin-bottom: 10px !important;
    font-size: 14px !important;
    line-height: 14px !important;
    min-height: 45px;
  }

  .videoCourseCardDescription span:nth-child(1) {
    margin-bottom: 10px;
  }

  .buttonInvideoCourseCard {
    height: 40px;
    background: #62def0 !important;
    border-radius: 30px !important;
    color: #1d1d1d !important;
    text-transform: capitalize !important;
    width: 235px;
    font-size: 14px !important;
  }

  .buttonInvideoCourseCard a {
    text-decoration: none !important;
    color: #1d1d1d !important;
  }

  .breathVideoBox {
    width: 100%;
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .breathVideoBox .addVideoBtn,
  .breathVideoBtn,
  .workshopVideoBtn {
    margin-bottom: 10px;
  }

  .workshopVideoBox {
    width: 100%;
    margin: 20px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .videoMainContainer {
    width: 1000px;
  }

  .videoCourseContent {
    width: 940px;
    margin-left: 30px;
  }

  .titleVideoCourse {
    margin: 0 0 0 366px;
    font-size: 32px;
    line-height: 32px;
    margin-top: 60px;
  }

  .introductionVideo {
    margin: 40px 0 0 366px;
  }

  .introductionVideo div {
    width: 100% !important;
  }

  .titleAddVideo,
  .titleMainVideo,
  .titleBreathVideo {
    margin: 0 0 0 215px;
    font-size: 32px;
    line-height: 32px;
  }

  .addVideoBox {
    margin: 40px 0 0 366px;
  }

  .mainVideoText {
    margin: 20px 0px 0px 366px;
  }

  .videoFirstGroup div,
  .videoSecondGroup div,
  .videoThirdGroup div {
    width: 268px !important;
    height: 360px !important;
    margin-right: 10px !important;
    justify-content: space-between;
    padding: 10px;
  }

  .videoCourseCardTitle {
    font-size: 24px;
  }

  .oilsAndBreathDescription {
    font-size: 16px;
  }

  .startVideoBtn a {
    font-size: 14px !important;
  }

  .breathVideoBox {
    margin: 40px 0 40px 366px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .breathVideoBox .addVideoBtn {
    margin-bottom: 20px;
  }
}

.boxBooking {
  width: 1440px;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-image: linear-gradient(
      to right,
      rgba(172, 214, 237, 0.4),
      rgba(172, 214, 237, 0.4)
    ),
    url("./IMG_0169.JPG");
  background-position: right;
  background-position-y: top;
}

.containerBooking {
  position: absolute;
  display: flex;
  background: #acd6ed;
  opacity: 0.9;
  width: 750px;
  flex-direction: column;
  padding: 20px;
}

.bookDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #555965;
  height: 100%;
}

.bookDate h2 {
  font-size: 24px;
}

.picker {
  display: flex;
  opacity: 0.7;
  width: 100%;
  justify-content: space-between;
}

.css-195y93z-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background: #62def0 !important;
}

.timeButtons {
  display: flex;
  flex-direction: column;
  padding-top: 9%;
  align-items: center;
  justify-content: space-evenly;
}

.timeButtons button {
  color: #1d1d1d;
}

.timeButtons button[disabled] {
  pointer-events: all !important;
  color: #808080;
}

.buttonBooking {
  font-size: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonInBooking button {
  font-size: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #187886;
  width: 190px;
  height: 150px;
}

.containerBookSum {
  color: #555965;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.containerBookSum h2 {
  font-size: 24px;
}

.dateAndPracticeP {
  font-size: 18px;
  color: #1d1d1d;
  margin: 0 0 40px 0;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .boxBooking {
    width: 340px;
    height: 750px;
  }

  .containerBooking {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .bookDate h2 {
    font-size: 20px;
  }

  .picker {
    flex-direction: column;
  }

  .css-l0iinn {
    min-width: fit-content;
    font-size: 12px !important;
  }

  .picker span {
    font-size: 10px !important;
  }

  .picker h4 {
    font-size: 0px !important;
  }

  .css-dhopo2 {
    min-height: 200px !important;
  }

  .css-169iwlq-MuiCalendarPicker-root {
    width: 300px !important;
  }

  .PrivatePickersToolbar-root {
    max-width: 1% !important;
    padding: 18px !important;
  }

  .buttonsLanguage {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0% !important;
  }

  .buttonsLanguage button {
    line-height: 10px;
  }

  .timeButtons {
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: row;
  }

  .buttonInBooking span {
    font-size: 14px !important;
  }

  .buttonInBooking {
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonBooking {
    width: 100%;
    border: 1px solid rgb(167 167 167 / 87%);
    border-radius: 5px;
    font-size: 16px !important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  .containerBookSum {
    margin: 5% 0;
  }

  .containerBookSum h2 {
    font-size: 14px;
  }

  .dateAndPracticeP,
  .buttonBooking {
    font-size: 14px;
  }
}

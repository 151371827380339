.aboutTitleBox {
  display: flex;
  width: 100%;
}

.titleAbout {
  margin: 0 0 0 200px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
  white-space: pre-line;
}

.titleAboutFirst {
  padding-left: 180px;
}

.titleAboutColor {
  color: #62def0;
}

.aboutMainText {
  display: flex;
  flex-direction: column;
  color: #fefaed;
  font-size: 18px;
  margin: 20px 0px 0px 546px;
  width: 348px;
}

.firstParagraghAbout {
  padding-bottom: 10px;
}

.imagesAboutBox {
  display: flex;
  margin-top: 40px;
}

.secondPhotoGroup {
  padding-left: 380px;
}

.thirdPhotoGroup {
  padding-left: 198px;
}

.aboutImg {
  width: 166px;
  height: 166px;
  object-fit: cover;
  object-position: left top;
}

.specialImgAbout {
  object-position: bottom;
  margin-right: 16px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .aboutTitleBox {
    flex-direction: column;
  }

  .titleAbout {
    margin: 20px 0 0 0;
    font-size: 20px;
    line-height: 20px;
  }

  .titleAboutFirst {
    padding-left: 0px;
  }

  .aboutMainText {
    font-size: 14px;
    margin: 20px 0 0 0;
    width: 100%;
  }

  .imagesAboutBox {
    justify-content: space-between;
    margin-top: 20px;
  }

  .specialImgAbout {
    margin-right: 0px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titleAbout {
    margin: 0;
    font-size: 32px;
    line-height: 32px;
  }

  .titleAboutFirst {
    padding-left: 142px;
  }

  .aboutMainText {
    font-size: 16px;
    margin: 20px 0px 0px 295px;
  }

  .secondPhotoGroup {
    padding-left: 170px;
  }
  .aboutImg {
    width: 146px;
    height: 146px;
  }

  .secondPhotoGroup {
    padding-left: 148px;
  }

  .thirdPhotoGroup {
    padding-left: 42px;
  }

  .specialImgAbout {
    margin-right: 10px;
  }
}

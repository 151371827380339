.meditationBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  height: 600px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  object-position: 20% 60%;
  filter: brightness(1.3);
}

.substrate {
  position: absolute;
  width: 348px;
  height: 480px;
  background: #62def0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
}

.meditionHeaderTitle {
  white-space: pre-wrap;
  font-size: 14px;
  opacity: 0.7;
  margin-bottom: 90px;
  margin-top: 20px;
  text-align: center;
}

.meditatiomMainTitle {
  text-transform: uppercase;
  font-size: 40px;
  margin-bottom: 10px;
}

.meditationSubTitle {
  font-size: 20px;
  margin-bottom: 100px;
}

.audioBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.audioBox audio {
  width: 328px !important;
}

audio::-webkit-media-controls-panel {
  background: #62def0 !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .substrate {
    width: 276px;
    height: 395px;
  }

  .audioBox audio {
    width: 276px !important;
  }

  .meditionHeaderTitle {
    font-size: 12px;
    margin-bottom: 80px;
    margin-top: 10px;
  }

  .meditatiomMainTitle {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .meditationSubTitle {
    font-size: 14px;
    margin-bottom: 80px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .meditatiomMainTitle {
    font-size: 32px;
  }

  .meditationSubTitle {
    font-size: 18px;
  }
}

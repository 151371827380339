.navigationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2% 0 0 80px;
  width: 1440px;
  height: 60px;
  background: #62def0;
  border-radius: 10px;
}

.logo {
  font-size: 24px;
  text-transform: uppercase;
  font-family: "bold" !important;
  padding-left: 20px;
  color: #1d1d1d;
}

.navigationItems {
  justify-content: space-between;
  width: 55%;
  display: flex;
  align-items: baseline !important;
}

.headerNavBtn {
  background: none;
  padding: 0px !important;
  border: none !important;
  cursor: pointer;
}

.headerNavBtn a {
  color: #1d1d1d !important;
}

.btn {
  font-size: 18px !important;
  color: #1d1d1d;
  text-decoration: none;
  padding: 0 !important;
}

.btn p {
  margin: 0px !important;
}

.headerActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin-right: 20px;
  max-height: 25px;
}

.languageBtn {
  margin-left: 20px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #acd6ed !important;
  opacity: 0.6 !important;
  color: #1d1d1d !important;
}

.css-qiwgdb,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 25px 0 10px !important;
  font-size: 14px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #c4c4c4 !important;
}

.css-igs3ac {
  border: none !important;
  padding: 0px !important;
}

.entranceBtn {
  border: 1px solid #555965;
  background: none;
  border-radius: 30px;
  font-size: 18px;
  color: #1d1d1d !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .navigationContainer {
    width: 340px;
    position: relative;
    height: 36px;
    z-index: 1;
    margin: 2% 0 0 0px;
  }

  .headerActions {
    margin-right: 0px;
    align-items: center;
  }

  .headerNavBtn {
    margin-right: 10px;
  }

  .languageBtn {
    margin-left: 0px;
  }

  .logo {
    padding: 5px 0px 0px 20px;
  }

  .css-qiwgdb,
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0 25px 0 0 !important;
    font-size: 12px !important;
  }

  .entranceBtn {
    font-size: 14px;
  }
}

@media (min-width: 640px) and (max-width: 900px) and (orientation: portrait) {
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .navigationContainer {
    width: 940px;
    height: 50px;
    margin: 2% 0 0 30px;
  }

  .navigationItems {
    width: 60%;
  }

  .btn {
    font-size: 16px !important;
  }
}

.guideBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 841px;
  height: 595px;
}

.pageInPdf {
  padding: 5%;
}

@media (min-width: 160px) and (max-width: 319px) {
  .guideBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 120px;
  }

  .pageInPdf {
    padding: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  canvas.react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    width: 140px !important;
    height: 120px !important;
  }
}

@media (min-width: 320px) and (max-width: 374px) and (orientation: portrait) {
  .guideBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 300px;
  }

  .pageInPdf {
    padding: 7%;
  }

  canvas.react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    width: 350px !important;
    height: 300px !important;
  }
}

@media (min-width: 375px) and (max-width: 413px) and (orientation: portrait) {
  .guideBox {
    position: absolute;
    top: 40%;
    left: 47%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 300px;
  }

  .pageInPdf {
    padding: 7%;
  }

  canvas.react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    width: 373px !important;
    height: 318px !important;
  }
}

@media (min-width: 414px) and (max-width: 767px) and (orientation: portrait) {
  .guideBox {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 413px;
    height: 360px;
  }

  .pageInPdf {
    padding: 7%;
  }

  canvas.react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    width: 413px !important;
    height: 360px !important;
  }
}

@media (min-width: 1280px) and (max-width: 2000px) {
  .guideBox {
    top: 44%;
    left: 50%;
  }

  .pageInPdf {
    padding: 0%;
  }
}

.border {
  position: absolute;
  z-index: 1;
  border: 1px solid #62def0;
  width: 1400px;
  height: 690px;
  margin: 20px 0px 0px 20px;
}

.yogaPeopleImg {
  position: relative;
  width: 1440px;
  height: 730px;
  object-fit: cover;
  object-position: 0% 80%;
}

.actionBox {
  width: 350px;
  min-height: 300px;
  margin: 60px 0px 0px 60px;
  background: #555965;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.actionTitle {
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.actionText {
  font-size: 18px;
  color: #fefaed;
  margin: 20px 0 40px 0;
  width: 348px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .border {
    flex-direction: column;
    display: flex;
    width: 320px;
    height: fit-content;
    margin: 0;
    padding: 10px;
    position: relative;
  }

  .yogaPeopleImg {
    width: 300px;
    height: 187px;
    margin-bottom: 20px;
  }

  .actionTitle {
    font-size: 20px;
    line-height: 20px;
    width: 200px;
  }

  .actionText {
    font-size: 14px;
    color: #fefaed;
    margin: 10px 0 20px 0;
    width: 100%;
  }

  .actionButton {
    width: 300px;
    height: 40px;
    border: none;
    background: #62def0;
    border-radius: 30px;
    color: #333333 !important;
    text-decoration: none !important;
    font-size: 14px !important;
  }

  .actionButton a {
    color: #333333 !important;
    text-decoration: none !important;
    font-size: 14px !important;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .yogaPeopleImg {
    width: 940px;
    height: 100%;
    object-position: 20% 80%;
  }

  .border {
    width: 900px;
    height: 584px;
  }

  .actionBox {
    min-height: 240px;
    margin: 40px 0px 0px 40px;
    padding: 20px;
  }

  .actionTitle {
    font-size: 32px;
    line-height: 32px;
  }

  .actionText {
    font-size: 16px;
    width: 320px;
  }
}

.textButton {
  text-decoration: underline;
  color: #62def0;
  font-size: 18px;
  margin: 40px 0px 0px 546px;
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
}

.textButton a {
  text-decoration: none;
  color: #62def0;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .textButton {
    font-size: 14px;
    margin: 20px 0px 0px 0px;
  }
}

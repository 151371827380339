.titleBox {
  display: flex;
  width: 100%;
}

.titleCourse {
  margin: 0 0 0 200px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.titleCourseColor {
  color: #62def0;
  padding-left: 180px;
}

.aboutCourse {
  margin: 20px 0 40px 546px;
  color: #fefaed;
  font-size: 18px;
  width: 530px;
}

.courseQuestionList {
  background: none !important;
  color: #fefaed !important;
  box-shadow: none !important;
}

.courseQuestionList::before {
  background: none !important;
}

.courseQuestion {
  padding: 0px !important;
  border-top: 1px solid #62def0 !important;
}

.courseQuestionList:nth-child(7) {
  border-bottom: 1px solid #62def0 !important;
}

.questionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}

.questionNum {
  font-size: 18px;
  margin-right: 525px;
}

.questionTitle {
  font-size: 24px;
  width: 530px;
}

.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #fefaed !important;
}

.questionDescription {
  margin-left: 546px;
  color: #fefaed;
  font-size: 18px;
  max-width: 712px;
  padding: 0 0 30px 0 !important;
}

.courseBtnBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.css-1fx8m19 {
  color: #fefaed !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .titleBox {
    flex-direction: column;
  }

  .titleCourse {
    margin: 20px 0 0 0;
    font-size: 20px;
    line-height: 20px;
  }

  .titleCourseColor {
    padding-left: 0px;
  }

  .aboutCourse {
    margin: 20px 0;
    font-size: 14px;
    width: 100%;
  }

  .questionContainer {
    margin: 10px 0;
  }

  .questionNum {
    font-size: 14px;
    margin-right: 0;
  }

  .questionTitle {
    font-size: 14px;
    max-width: 240px;
    padding-left: 10px;
  }

  .questionDescription {
    margin-left: 0;
    font-size: 12px;
    padding: 0 0 10px 26px !important;
  }
}
@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titleCourse {
    margin: 0;
    font-size: 32px;
    line-height: 32px;
  }

  .titleCourseColor {
    padding-left: 144px;
  }

  .aboutCourse {
    margin: 20px 0 40px 296px;
    font-size: 16px;
  }

  .questionNum {
    font-size: 16px;
    margin-right: 278px;
  }

  .questionDescription {
    margin-left: 295px;
    font-size: 16px;
  }
}

.toursContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toursTitleBox {
  display: flex;
  width: 100%;
}

.titleTours {
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
  margin: 0 0 0 200px;
}

.titleToursFirst {
  margin-left: 180px;
  width: 840px;
}

.titleToursSecond {
  width: 1076px;
}

.titleToursColor {
  color: #62def0;
}

.toursList {
  margin: 40px 0;
}

.toursBlock {
  display: flex;
  margin-left: 546px;
}

.toursBlockFull {
  display: flex;
}

.toursOfferList {
  width: 894px;
  background: none !important;
  color: #fefaed !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.toursBlockFull .toursOfferList {
  margin-left: 198px !important;
}

.toursOfferList::before {
  background: none !important;
}

.tourOfferTitleContainer {
  padding: 0px !important;
}

.toursList .toursBlock:nth-child(3) {
  border-bottom: 1px solid #62def0 !important;
}

.toursList .toursBlock {
  border-top: 1px solid #62def0 !important;
}

.toursBlockFull .tourOfferTitleContainer {
  border-top: 1px solid #62def0 !important;
}

.tourOfferTitle {
  font-size: 32px;
  margin: 30px 0;
}

.css-o4b71y-MuiAccordionSummary-content {
  margin: 0 !important;
}

.imgInTour {
  height: 444px;
  width: 348px;
}

.tourOfferDescriptionContainer {
  display: flex;
  padding: 10px 0 !important;
}

.tourOfferDescription {
  display: flex;
  flex-direction: column;
  width: 530px;
  font-size: 18px;
}

.tourMainDescription {
  white-space: pre-line;
}

.tourAddDescription {
  padding-top: 20px;
  width: 348px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .toursTitleBox {
    flex-direction: column;
  }

  .titleTours {
    font-size: 20px;
    line-height: 20px;
    margin: 20px 0 0 0;
  }

  .titleToursFirst {
    margin-left: 0;
    width: 100%;
  }

  .titleToursSecond {
    width: 100%;
  }

  .toursList {
    margin: 20px 0 40px 0;
  }

  .toursBlock {
    margin-left: 0;
    width: 100%;
  }

  .toursBlockFull {
    height: fit-content;
  }

  .toursBlockFull .toursOfferList {
    margin-left: 0 !important;
  }

  .toursOfferList {
    width: 340px;
  }

  .tourOfferTitle {
    font-size: 14px;
    margin: 10px 0;
  }

  .tourOfferDescription {
    width: 100%;
    font-size: 14px;
  }

  .tourAddDescription {
    padding-top: 10px;
    width: 100%;
    font-size: 12px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titleTours {
    font-size: 32px;
    line-height: 32px;
    margin: 0;
  }

  .titleToursFirst {
    margin-left: 142px;
    width: 658px;
  }

  .titleToursSecond {
    width: 100%;
  }

  .toursBlock {
    margin-left: 295px;
  }

  .toursOfferList {
    width: 646px;
  }

  .tourOfferTitle {
    font-size: 24px;
  }

  .imgInTour {
    height: 327px;
    width: 256px;
  }

  .toursBlockFull .toursOfferList {
    margin-left: 40px !important;
  }

  .tourOfferDescription {
    font-size: 16px;
  }
}

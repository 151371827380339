.essentialOilsTitleBox {
  display: flex;
  width: 100%;
}

.titleEssentialOils {
  margin: 0 0 0 200px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.titleEssentialOilsFirst {
  padding-left: 180px;
}

.titleEssentialOilsColor {
  color: #62def0;
}

.essentialOilsMainText {
  display: flex;
  flex-direction: column;
  color: #fefaed;
  font-size: 18px;
  margin: 20px 0px 0px 546px;
  width: 530px;
}

.firstParagraghOils {
  padding-bottom: 10px;
}

.essentialOilsImg {
  margin-top: 40px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .essentialOilsTitleBox {
    flex-direction: column;
  }

  .titleEssentialOils {
    margin: 20px 0 0 0;
    font-size: 20px;
    line-height: 20px;
  }

  .titleEssentialOilsFirst {
    padding-left: 0px;
  }

  .essentialOilsMainText {
    font-size: 14px;
    margin: 20px 0px 0px 0px;
    width: 100%;
  }

  .essentialOilsImg {
    width: 100%;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titleEssentialOils {
    margin: 0;
    font-size: 32px;
    line-height: 32px;
  }

  .titleEssentialOilsFirst {
    padding-left: 142px;
  }

  .essentialOilsMainText {
    font-size: 16px;
    margin: 20px 0px 0px 296px;
  }

  .essentialOilsImg {
    width: 100%;
  }
}

.logBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
}

.loginForm {
  width: 540px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #62def0;
  opacity: 0.9;
}

.entryAndRegButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #667280;
  font-size: 18px;
}

.buttonRegistration {
  width: fit-content;
  min-width: 200px;
  background: none;
  border: none;
}

.buttonRegistration a {
  color: #667280;
  font-size: 18px;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginContainer label {
  opacity: 0.6;
}

.loginInput {
  margin-bottom: 30px !important;
}

.loginInput input {
  width: 300px;
  height: 10px;
}

.loginContainer div::after {
  border-bottom: 1px solid #667280;
}

.loginContainer label.Mui-focused {
  color: #667280;
}

.passwordFormLogin {
  width: 90%;
}

.forgotPasswordLink {
  color: #667280;
  font-size: 18px;
}

.buttonLog,
.buttonLogWithGoogle {
  width: 50%;
  height: 40px;
  font-size: 18px;
  border-radius: 30px;
  background-color: #667280;
  border-color: #667280;
  color: #fefaed;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .loginForm {
    width: 340px;
    height: 65%;
  }

  .entryAndRegButton {
    flex-direction: column;
    align-items: center;
  }

  .entryAndRegButton h3 {
    font-size: 20px;
  }

  .buttonRegistration,
  .buttonLog,
  .buttonRegistration a,
  .buttonLogWithGoogle {
    min-width: 90%;
    font-size: 14px;
  }

  .loginForm input {
    width: 100%;
  }

  .loginInput {
    width: 100%;
  }

  .loginContainer {
    width: 90%;
  }

  .loginContainer label {
    font-size: 12px;
  }

  .passwordFormLogin {
    width: 100%;
    align-items: center;
  }

  .forgotPasswordLink {
    font-size: 14px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .loginForm {
    height: 60%;
  }
}

.adminPage {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5%;
}

.adminPage h2 {
  text-align: center;
}

.adminPagePersonBlock,
.adminPageScheduleBlock {
  width: 45%;
}

.personDataForAdmin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dayInSchedule {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  padding: 3%;
}

.dayInSchedule p {
  font-size: 20px;
}

.bookingBlock {
  width: 50%;
}

.bookingInfo,
.bookingInfoName {
  display: flex;
  justify-content: space-evenly;
}

.scheduleInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@font-face {
  font-family: "bold";
  src: local("InterBold"),
    url("./fonts/HelveticaNeueCyr-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "regular";
  src: local("InterItalic"),
    url("./fonts/HelveticaNeueCyr-Roman.ttf") format("truetype");
}

* {
  font-family: "regular" !important;
}

html {
  position: relative;
  background-size: cover;
}

body {
  margin: 0;
  background: #555965;
  display: flex;
  justify-content: center;
}

.descriptor {
  color: #fefaed;
  font-size: 18px;
  width: 166px;
  min-width: 166px;
  margin: 0;
}

@media (min-width: 346px) and (max-width: 440px) and (orientation: portrait) {
  .descriptor {
    font-size: 14px;
    width: 115px;
    min-width: 115px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .descriptor {
    font-size: 16px;
    min-width: 150px;
  }
}

.containedButton {
  width: 348px;
  height: 60px;
  border: none;
  background: #62def0;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none !important;
}

.containedButton a {
  color: #333333 !important;
  font-size: 18px !important;
  text-decoration: none !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .containedButton {
    width: 320px;
    height: 45px;
    color: #333333 !important;
    text-decoration: none !important;
    font-size: 14px !important;
  }

  .containedButton a {
    font-size: 14px !important;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .containedButton {
    height: 50px;
  }

  .containedButton a {
    font-size: 16px !important;
  }
}

.resetPasswordForm div {
  border-bottom: 1px solid #667280;
}

.resetPasswordForm div::after {
  border-bottom: 1px solid #667280;
}

.resetPasswordForm label.Mui-focused {
  color: #667280;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #667280 !important;
}

.incorrectResetEmail {
  color: #ef1b1b;
  text-align: center;
}

.resetEmailBtns button {
  color: #667280;
}

.reviewsTitleBox {
  display: flex;
  width: 100%;
}

.titleReviews {
  margin: 0 0 40px 380px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.titleReviewsColor {
  color: #62def0;
}

.reviewVideo {
  width: 450px;
  margin: auto;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .reviewsTitleBox {
    flex-direction: column;
  }

  .titleReviews {
    margin: 20px 0;
    font-size: 20px;
    line-height: 20px;
  }

  .reviewVideo {
    width: 340px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titleReviews {
    margin: 0 0 40px 128px;
    font-size: 32px;
    line-height: 32px;
  }
}

.corporateTitleBox {
  display: flex;
  width: 100%;
}

.titleCorporate {
  margin: 0 0 0 200px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
  display: flex;
  flex-direction: column;
}

.titleCorporateFirstPart {
  padding-left: 180px;
  width: 894px;
}

.titleCorporateColor {
  color: #62def0;
}

.titleCorporateSecondPart {
  width: 910px;
}

.corporeteMainText {
  display: flex;
  color: #fefaed;
  font-size: 18px;
  margin: 20px 0px 0px 546px;
}

.firstTextGroup {
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-right: 16px;
}

.firstTextGroup span:first-child {
  padding-bottom: 10px;
}

.secondTextGroup {
  width: 348px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .corporateTitleBox {
    flex-direction: column;
  }

  .titleCorporate {
    margin: 20px 0;
    font-size: 20px;
    line-height: 20px;
  }

  .titleCorporateFirstPart {
    padding-left: 0px;
    width: 100%;
  }

  .titleCorporateSecondPart {
    width: 100%;
  }

  .corporeteMainText {
    display: flex;
    flex-direction: column;
    color: #fefaed;
    font-size: 14px;
    margin: 0;
  }

  .firstTextGroup {
    width: 100%;
    margin-right: 0px;
  }

  .firstTextGroup span {
    padding-bottom: 10px;
  }

  .secondTextGroup {
    width: 100%;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .titleCorporate {
    margin: 0;
    font-size: 32px;
    line-height: 32px;
  }

  .titleCorporateFirstPart {
    padding-left: 142px;
    width: 648px;
  }

  .titleCorporateSecondPart {
    width: 100%;
  }

  .corporeteMainText {
    font-size: 16px;
    margin: 20px 0px 0px 297px;
  }

  .textButton {
    font-size: 16px;
    margin: 40px 0px 0px 296px;
  }
}

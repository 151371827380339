.coverContainer {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right -100px center;
  width: 1600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.coverImg {
  width: 100%;
  transform: scale(1.5);
  margin-top: 25px;
}

.cover {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 10% 0 0 80px;
}

.coverTitle {
  font-size: 200px;
  font-family: "bold" !important;
  line-height: 80%;
  text-transform: uppercase;
  color: #fefaed;
  width: 50%;
  margin-bottom: 10px;
}

.coverText {
  font-size: 24px;
  font-family: "bold" !important;
  color: #fefaed;
  width: 530px;
  margin-bottom: 80px;
}

@media (min-width: 360px) and (max-width: 400px) and (orientation: portrait) {
  .coverContainer {
    width: 360px;
    height: 100%;
  }

  .cover {
    width: 340px;
    margin: 13% 0 0 10px;
  }

  .coverImg {
    width: 79%;
    height: calc(100vw * 0.65);
    transform: scale(1.36);
    margin-top: 10px;
    margin-left: 10%;
  }

  .coverTitle {
    font-size: 50px;
    width: 100%;
    line-height: 40px;
    margin-top: 40px;
  }

  .coverText {
    font-size: 18px;
    width: 100%;
    margin-bottom: 40px;
  }
}

@media (min-width: 401px) and (max-width: 440px) and (orientation: portrait) {
  .coverContainer {
    width: 360px;
    height: 100%;
  }

  .cover {
    width: 340px;
    margin: 13% 0 0 10px;
  }

  .coverImg {
    width: 90%;
    height: calc(100vw * 0.65);
    transform: scale(1.36);
    margin-top: 10px;
    margin-left: 5%;
  }

  .coverTitle {
    font-size: 50px;
    width: 100%;
    line-height: 40px;
    margin-top: 40px;
  }

  .coverText {
    font-size: 18px;
    width: 100%;
    margin-bottom: 40px;
  }
}

@media (min-width: 640px) and (max-width: 900px) and (orientation: portrait) {
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .coverContainer {
    width: 1000px;
  }

  .cover {
    width: 940px;
    margin: 12% 0 0 30px;
  }

  .coverTitle {
    font-size: 135px;
    margin-bottom: 20px;
  }

  .coverText {
    font-size: 20px;
    width: 310px;
    margin-bottom: 100px;
  }
}

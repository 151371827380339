.footerContainer {
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
}

.footerFirstBlock {
  display: flex;
  flex-direction: column;
}

.footerTitle {
  font-size: 40px;
  line-height: 40px;
  color: #fefaed;
}

.underLogoText {
  width: 530px;
  opacity: 0.7;
  margin-top: 20px;
}

.contactInfo {
  margin: 60px 0 20px 0;
  width: 348px;
}

.footerText {
  font-size: 18px;
  color: #fefaed;
}

.footerSecondBlock {
  display: flex;
  flex-direction: column;
  width: 530px;
}

.userCommentEmail {
  margin-top: 50px !important;
}

.userCommentEmail label,
.emailIcon svg {
  color: #fefaed;
}

.css-ghsjzk-MuiInputBase-root-MuiInput-root:before,
.css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 1px solid #fefaed !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fefaed !important;
}

.navigationLinksBox {
  display: flex;
  margin-top: 60px;
}

.navigationBlock {
  width: 348px;
  display: flex;
  flex-direction: column;
  height: 115px;
  flex-wrap: wrap;
}

.footerNavBtn {
  text-decoration: none;
  color: #fefaed;
  font-size: 18px;
  margin-bottom: 20px;
}

.footerIcon {
  margin-right: 10px;
}

@media (min-width: 346px) and (max-width: 440px) and (orientation: portrait) {
  .footerContainer {
    flex-direction: column;
    margin: 0;
  }

  .footerTitle {
    font-size: 20px;
    line-height: 20px;
  }

  .underLogoText {
    width: 100%;
  }

  .footerSecondBlock {
    width: 100%;
  }

  .navigationBlock {
    width: 165px;
    height: fit-content;
    flex-wrap: nowrap;
  }

  .contactInfo {
    margin: 0px 0 20px 0;
    width: 161px;
  }

  .footerText {
    font-size: 14px;
  }

  .footerNavBtn {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .footerAddressBlock {
    display: flex;
    flex-direction: column;
  }

  .footerAddressBlockIcons {
    display: flex;
    padding-top: 10px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .footerText {
    font-size: 16px;
    width: 90%;
  }

  .footerFirstBlock,
  .footerSecondBlock {
    width: 49%;
  }

  .navigationBlock {
    width: 325px;
  }

  .footerNavBtn {
    font-size: 16px;
  }
}

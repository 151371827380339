.retreatBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 20% 60%;
}

.retreatInfoContainer {
  display: flex;
  flex-direction: column;
  margin: 0% 0% 5% 3%;
  align-items: flex-start;
}

.promotionTitle {
  font-size: 55px;
  color: #ffffff;
  width: 75%;
  padding-bottom: 20px;
}

.promotionSubTitle {
  font-size: 24px;
  color: #ffffff;
  padding-bottom: 20px;
}

.promotionButton {
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
  width: 200px;
  height: 40px;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff3d;
}

.closeIconPromotion {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  cursor: pointer;
}

.closeIconPromotion svg {
  height: 20px;
  padding: 1%;
}

@media (min-width: 320px) and (orientation: portrait) {
  .retreatBox {
    width: 100%;
    height: 100%;
  }

  .promotionTitle {
    font-size: 30px;
    width: 100%;
  }

  .retreatInfoContainer {
    margin: 0% 0% 35% 2%;
  }

  .promotionSubTitle {
    font-size: 14px;
  }

  .promotionButton {
    font-size: 14px;
  }
}

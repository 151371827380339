.personalCabinetPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgba(98, 222, 240), rgba(98, 222, 240));
  height: 100vh;
  width: 1440px;
}

.cabinetPage {
  display: flex;
  flex-direction: column;
  width: 60%;
  color: #fefaed;
  background: #555965;
  box-shadow: 0px 30px 60px rgb(34 31 32 / 60%);
  padding: 50px 30px;
}

.titleBooking {
  text-align: center;
  color: #fefaed;
  font-size: 24px;
}

.personalInfoBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.personalInfo p {
  font-size: 18px;
}

.avatar_Data {
  display: none;
  width: 300px;
}

.addPhotoInput {
  display: flex;
  align-items: center;
  width: 130px;
  justify-content: space-evenly;
  font-size: 18px;
  height: 50px;
}

.avatarImgAndButton {
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
}

.avatarImgAndButton img {
  cursor: pointer;
}

.bookings {
  font-size: 24px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 2%;
  border-top: solid 1px #62def0;
}

.goToCourseBtn {
  width: 348px;
  height: 60px;
  border: none;
  background: #62def0;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none !important;
}

.goToCourseBtn a {
  color: #333333 !important;
  font-size: 18px !important;
  text-decoration: none !important;
}

.prevBook,
.futureBook {
  flex-direction: column;
  justify-content: space-evenly;
}

.bookingsList {
  max-height: 300px;
  overflow-y: auto;
}

.bookingOfUser {
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.pdfGuide {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 40px;
}

.guidesText {
  width: 500px;
  font-size: 24px;
}

.guides {
  display: flex;
  cursor: pointer;
  width: 80%;
  justify-content: space-evenly;
  color: #62def0;
}

.guides h4 {
  text-decoration: underline;
  font-size: 18px;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .personalCabinetPage {
    width: 340px;
    min-height: 750px;
    height: 100%;
  }

  .cabinetPage {
    padding: 10px;
    width: 90%;
  }

  .personalInfoBox {
    width: 100%;
    padding-left: 0px;
    flex-direction: column;
  }

  .personalInfo h3 {
    font-size: 20px;
  }

  .personalInfo p {
    font-size: 14px;
  }

  .avatarImgAndButton {
    height: 80px;
    margin: 10% 0%;
  }

  .avatar {
    width: 120px !important;
    height: 120px !important;
  }

  .bookings {
    flex-direction: column;
    padding-top: 0%;
    margin-bottom: 5%;
  }

  .goToCourseBtn {
    height: 40px;
    color: #333333 !important;
    text-decoration: none !important;
    font-size: 14px !important;
    width: 280px;
    margin: 10% 0%;
  }

  .goToCourseBtn a {
    font-size: 14px !important;
  }

  .bookingsList {
    max-height: 90px;
  }

  .prevBook, .futureBook{
    margin-top: 20px;
  }

  .titleBooking {
    font-size: 20px;
  }

  .bookingOfUser {
    font-size: 14px;
  }

  .pdfGuide {
    width: 100%;
  }

  .guidesText {
    width: 100%;
    font-size: 20px;
  }

  .guides {
    flex-direction: column;
    width: 100%;
  }

  .guides h4 {
    font-size: 14px;
  }
}

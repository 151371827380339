.regBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
}

.regForm {
  width: 540px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #62def0;
  opacity: 0.9;
}

.entryAndLogButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #667280;
  font-size: 18px;
}

.registrationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.registrationContainer label {
  opacity: 0.6;
}

.registrationInput {
  margin-bottom: 30px !important;
}

.regForm input {
  width: 300px;
  height: 10px;
}

.registrationContainer div::after {
  border-bottom: 1px solid #667280;
}

.registrationContainer label.Mui-focused {
  color: #667280;
}

.passwordForm {
  width: 90%;
}

.incorrectPasswordMessage {
  color: #ef1b1b;
  text-align: center;
  padding: 0% 2%;
}

.buttonReg,
.buttonRegWithGoogle {
  width: 50%;
  height: 40px;
  font-size: 18px;
  border-radius: 30px;
  background-color: #667280;
  border-color: #667280;
}

.buttonReg a,
.buttonRegWithGoogle {
  text-decoration: none;
  color: #fefaed !important;
}

@media (min-width: 360px) and (max-width: 440px) and (orientation: portrait) {
  .regForm {
    width: 340px;
    height: 65%;
  }

  .entryAndLogButton {
    flex-direction: column;
    align-items: center;
  }

  .entryAndLogButton h3 {
    font-size: 20px;
  }

  .buttonRegistration,
  .buttonReg,
  .buttonRegistration a,
  .buttonRegWithGoogle {
    min-width: 90%;
    font-size: 14px;
  }

  .regForm input {
    width: 100%;
  }

  .registrationInput {
    width: 100%;
  }

  .registrationContainer {
    width: 90%;
  }

  .registrationContainer label {
    font-size: 12px;
  }

  .passwordForm {
    width: 100%;
    align-items: center;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .regForm {
    height: 60%;
  }
}

.coursePackagesContainer {
  width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.coursePackagesContent {
  width: 1440px;
  margin-left: 80px;
}

.titlePackagesBox {
  display: flex;
  margin-top: 120px;
}

.titlePackages {
  margin: 0 0 40px 436px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.titlePackagesColor {
  color: #62def0;
}

.coursePackagesBox {
  display: flex;
  justify-content: space-between;
}

.coursePackagesImg {
  height: 596px;
}

.coursePackagesList {
  width: 894px;
  padding-top: 0px !important;
}

.packageItem {
  border-top: #62def0 1px solid !important;
  background: none !important;
  padding: 30px 0 !important;
}

.packageItem:last-child {
  border-bottom: #62def0 1px solid !important;
}

.packageItemPoint,
.packageItemPoint span {
  width: 10px;
  max-width: 10px;
}

.packageItemText {
  text-align: start;
  padding-left: 172px;
  max-width: 620px;
}

.packageItemText span {
  color: #fefaed;
  font-size: 24px;
}

.titleTariff {
  margin: 0 0 40px 477px;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 40px;
  color: #fefaed;
}

.tariffCardsBox {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 0px 182px;
  max-width: 1076px;
}

.tariffCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 348px;
  height: 652px;
  background: #667280 !important;
}

.activeCard {
  background: #62def0 !important;
}

.tariffCardTitle {
  color: #fefaed;
  font-size: 32px !important;
}

.activeCard .tariffCardTitle {
  color: #1d1d1d;
}

.tariffCardBody {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

.tariffCardFooter {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px 20px 20px !important;
}

.tariffCardDescription {
  color: #fefaed;
  font-size: 18px !important;
  white-space: pre-line;
}

.activeCard .tariffCardDescription {
  color: #1d1d1d;
}

.tariffCardPrice {
  color: #fefaed;
  margin-bottom: 20px !important;
  font-size: 24px !important;
  width: 100%;
}

.activeCard .tariffCardPrice {
  color: #1d1d1d;
}

.buttonInTariffCard {
  width: 308px;
  height: 40px;
  background: #62def0 !important;
  border-radius: 30px !important;
  color: #1d1d1d !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeCard .buttonInTariffCard {
  background: #667280 !important;
}

@media (min-width: 346px) and (max-width: 440px) and (orientation: portrait) {
  .coursePackagesContainer {
    width: 360px;
    margin-bottom: 40px;
  }

  .coursePackagesContent {
    width: 340px;
    margin-left: 0px;
  }

  .titlePackagesBox {
    margin-top: 20px;
    flex-direction: column;
  }

  .titlePackages {
    margin: 20px 0;
    font-size: 20px;
    line-height: 20px;
  }

  .carousel-container {
    width: 340px;
    margin-top: 0;
  }

  .coursePackagesList {
    width: 340px;
  }

  .packageItem {
    padding: 10px 0 !important;
  }

  .packageItemText {
    padding-left: 10px;
    max-width: 280px;
  }

  .packageItemText span {
    font-size: 14px;
  }

  .titleTariff {
    margin: 20px 0;
    font-size: 20px;
    line-height: 20px;
  }

  .tariffCardsBox {
    margin: 0px;
    max-width: 340px;
  }

  .tariffCard {
    width: 270px !important;
    height: 520px;
  }

  .tariffCardsBox .react-multi-carousel-item--active {
    margin-right: 10px;
  }

  .tariffCardTitle {
    font-size: 20px;
  }

  .tariffCardFooter {
    padding: 0px 10px 10px 10px !important;
  }

  .tariffCardDescription {
    font-size: 14px !important;
  }

  .tariffCardPrice {
    margin-bottom: 10px !important;
    font-size: 18px !important;
  }

  .buttonInTariffCard {
    width: 230px;
    height: 40px;
    font-size: 14px;
  }
}

@media all and (min-width: 1000px) and (max-width: 1300px) {
  .coursePackagesContainer {
    width: 1000px;
  }

  .coursePackagesContent {
    width: 940px;
    margin-left: 0px;
  }

  .titlePackages {
    margin: 0 0 40px 128px;
    font-size: 32px;
    line-height: 32px;
  }

  .coursePackagesBox {
    width: 940px;
  }

  .coursePackagesImg {
    height: 577px;
  }

  .coursePackagesList {
    width: 520px;
  }

  .packageItem {
    padding: 20px 0 !important;
  }

  .packageItemText {
    padding-left: 20px;
  }

  .packageItemText span {
    font-size: 20px;
  }

  .titleTariff {
    margin: 0 0 40px 127px;
    font-size: 32px;
    line-height: 32px;
  }

  .tariffCardsBox {
    margin: 40px 0px 0px 0px;
    max-width: 940px;
  }

  .tariffCard {
    max-width: 300px;
    height: 570px;
  }

  .tariffCardTitle {
    font-size: 24px !important;
  }

  .tariffCardDescription {
    color: #fefaed;
    font-size: 16px !important;
  }

  .buttonInTariffCard {
    width: 260px;
    font-size: 16px !important;
  }
}
